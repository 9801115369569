import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";
import "firebase/database";
import "firebase/analytics";
// import { erpSessionStatus } from "./Erpnext";

export const provider = new firebase.auth.GoogleAuthProvider();

const firebaseConfig = {
  apiKey: "AIzaSyBkIYPXKdnod8-pkXtSn63zeyqV4LcfZjM",
  authDomain: "itj-b2b.firebaseapp.com",
  databaseURL: "https://itj-b2b.firebaseio.com",
  projectId: "itj-b2b",
  storageBucket: "itj-b2b.appspot.com",
  messagingSenderId: "1091567266147",
  appId: "1:1091567266147:web:b9c8bbc24fe3ece8a6cb56",
  measurementId: "G-SNKG1YH5JS",
};

// TEST CONFIG
// const firebaseConfig = {
//   apiKey: "AIzaSyDXfsvjycgnyBglp-IwhS3ZIZGxptDfYF8",
//   authDomain: "itj-b2b-test.firebaseapp.com",
//   databaseURL:
//     "https://itj-b2b-test-default-rtdb.europe-west1.firebasedatabase.app",
//   projectId: "itj-b2b-test",
//   storageBucket: "itj-b2b-test.appspot.com",
//   messagingSenderId: "252769009590",
//   appId: "1:252769009590:web:6a66106c6e26f0da720b17",
// };

firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore();
export const auth = firebase.auth();
export const storage = firebase.storage();
export const functions = firebase.app().functions("us-central1");
const rtdbRef = firebase.database();

export const getCustomerInfoRTDB = async () => {
  var userId = auth.currentUser.uid;
  return await rtdbRef.ref("/users/" + userId).once("value");
};

if (window.location.hostname === "localhost") {
  //auth.useEmulator('http://localhost:9099/');
  functions.useEmulator("localhost", "5001");
}

export const database = firebase.database();

export function getFirebaseImage(filename) {
  const fullPath = "gs://itj-b2b.appspot.com/" + filename;
  var gsReference = storage.refFromURL(fullPath);
  const url = gsReference.getDownloadURL();
  return url;
}

export function firebaseSignInWithEmail(email, password) {
  return auth.signInWithEmailAndPassword(email, password);
}

export function firebaseSignOut() {
  //erpSessionStatus("User logged out")
  return auth.signOut();
}

export function firebaseResetPassword(emailAddress, actionCodeSettings) {
  return auth.sendPasswordResetEmail(emailAddress, actionCodeSettings);
}

export const firebaseAnalytics = firebase.analytics();

export function firebaseChangeLanguage(language) {
  return (auth.languageCode = language);
}

export const updateLeadActivity = async (data) => {
  var updateLeadActivity = functions.httpsCallable("updateLeadActivity");
  return await updateLeadActivity(data);
};

export const updateRtdbLanguage = async (data) => {
  var updateRtdbLanguage = functions.httpsCallable("updateRtdbLanguage");
  return await updateRtdbLanguage(data);
};

export const updateRtdbCurrency = async (data) => {
  var updateRtdbCurrency = functions.httpsCallable("updateRtdbCurrency");
  return await updateRtdbCurrency(data);
};

export const shoppingCartRules = async (data) => {
  var getShoppingCartRules = functions.httpsCallable("getShoppingCartRules");
  return await getShoppingCartRules(data);
};

export const staticWebPage = async (data) => {
  var getStaticWebPage = functions.httpsCallable("getStaticWebPage");
  return await getStaticWebPage(data);
};

export const getBootInfo = async (data) => {
  var getBootInfo = functions.httpsCallable("getBootInfo");
  return await getBootInfo(data);
};

export const productPageInfo = async (data) => {
  var getProductPageInfo = functions.httpsCallable("getProductPageInfo");
  return await getProductPageInfo(data);
};

export const discountedPriceInfo = async (data) => {
  var getDiscountedPriceInfo = functions.httpsCallable(
    "getDiscountedPriceInfo"
  );
  return await getDiscountedPriceInfo(data);
};

export const searchDiscountedPriceInfo = async (data) => {
  var getSearchDiscountedPriceInfo = functions.httpsCallable(
    "getSearchDiscountedPriceInfo"
  );
  return await getSearchDiscountedPriceInfo(data);
};

export const fetchSearchDiscountedPrice = async (data) => {
  var fetchSearchDiscountedPriceInfo = functions.httpsCallable(
    "fetchSearchDiscountedPriceInfo"
  );
  return await fetchSearchDiscountedPriceInfo(data);
};

export const customerInfo = async (data) => {
  var getCustomerInfo = functions.httpsCallable("getCustomerInfo");
  return await getCustomerInfo(data);
};

export const updateCart = async (data) => {
  var setCartProductUpdate = functions.httpsCallable("setCartProductUpdate");
  return await setCartProductUpdate(data);
};

export const orderCheckout = async (data) => {
  var checkoutOrder = functions.httpsCallable("checkoutOrder");
  return await checkoutOrder(data);
};

export const updateOrderAvailability = async (data) => {
  var updateOrderByAvailability = functions.httpsCallable(
    "updateOrderByAvailability"
  );
  return await updateOrderByAvailability(data);
};

export const activeOrder = async (data) => {
  var getActiveOrder = functions.httpsCallable("getActiveOrder");
  return await getActiveOrder(data);
};

export const pastOrder = async (data) => {
  var getPastOrder = functions.httpsCallable("getPastOrder");
  return await getPastOrder(data);
};

export const pastOrderDetails = async (data) => {
  var getPastOrderDetails = functions.httpsCallable("getPastOrderDetails");
  return await getPastOrderDetails(data);
};

export const orderByCountry = async (data) => {
  var setOrderByCountry = functions.httpsCallable("setOrderByCountry");
  return await setOrderByCountry(data);
};

export const updateCustomerProfile = async (data) => {
  var setCustomerProfileUpdate = functions.httpsCallable(
    "setCustomerProfileUpdate"
  );
  return await setCustomerProfileUpdate(data);
};

export const announcementText = async () => {
  var getAnnouncementText = functions.httpsCallable("getAnnouncementText");
  return await getAnnouncementText();
};

export const esSearchItems = async (data) => {
  var esSearchItemsCallable = functions.httpsCallable("esSearchItems");
  return await esSearchItemsCallable(data);
};
